import { io } from 'socket.io-client';
export const socket = io(`${process.env.REACT_APP_PROXY_URL}`);

socket.on('jobAdded', (job) => {
  new Notification('New Job!', {
    body: `A new job job was just added for ${job.customer.name}`,
    silent: false,
  });
});
socket.on('disconnect', () => {
  socket.connect();
});
socket.on('connection_error', (error) => {
  console.log(error);
});
