function Heading({ className, children, primary, secondary, tertiary }) {
  const textSize = primary
    ? 'text-xl md:text-2xl'
    : secondary
    ? 'text-lg md:text-xl'
    : tertiary && 'text-md md:text-lg';
  return (
    <h1 className={`text-accent-main font-bold ${textSize} ${className}`}>
      {children}
    </h1>
  );
}

export default Heading;
