import React, { useEffect } from 'react';
import Heading from '../uiComponents/Heading';
import Input from '../uiComponents/Input';
import { useForm } from 'react-hook-form';
import { useAddCustomerMutation } from '../../store';
import { showLoadingNotificationEffect } from '../../utils/helpers';
export default function CustomerForm() {
  const [addCustomer, addCustomerResults] = useAddCustomerMutation();
  const { register, handleSubmit } = useForm();
  const handleFormSubmit = (form) => {
    addCustomer(form);
  };

  useEffect(() => {
    showLoadingNotificationEffect(addCustomerResults, 'Customer', 'Adding');
  }, [addCustomerResults]);
  return (
    <form
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(handleFormSubmit)}
      className="flex flex-col gap-2 mt-4 w-1/5 bg-dark-light-1 p-8 rounded-md "
    >
      <div className="flex flex-col gap-2">
        <div>
          <Heading primary>Add Customer</Heading>
        </div>
        <div className="flex flex-col gap-2">
          <Input
            label="Customer Name"
            id="name"
            register={register}
            placeholder="i.e. Afghanistan International Bank"
          />
          <Input
            label="Customer Initials"
            id="initials"
            register={register}
            placeholder="i.e. AIB"
          />
          <button
            className="mt-4 p-2 text-md font-bold uppercase bg-accent-main h-10 text-center text-white rounded-md hover:bg-accent-light transition"
            type="submit"
          >
            add
          </button>
        </div>
      </div>
    </form>
  );
}
