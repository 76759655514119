function Table({ data, config }) {
  const renderedHeaders = config.map((column) => {
    return (
      <th className="p-2  text-accent-main bg-dark-light-2" key={column.label}>
        {column.label}
      </th>
    );
  });

  const renderedRows = data.map((rowData) => {
    const renderedCells = config.map((column) => {
      return (
        <td className="p-2 font-semibold" key={column?.label}>
          {column?.render(rowData)}
        </td>
      );
    });
    return (
      <tr
        key={rowData._id}
        className="even:bg-dark-light-2 odd:bg-dark-light-1 hover:bg-dark-light-3 text-gray-100 hover:text-accent-main transition-all"
      >
        {renderedCells}
      </tr>
    );
  });

  return (
    <table className="table-auto w-full ">
      <thead className="h-2">
        <tr className="bg-white text-left">{renderedHeaders}</tr>
      </thead>
      <tbody className="h-full">{renderedRows}</tbody>
    </table>
  );
}

export default Table;
