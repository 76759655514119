import { useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { useDispatch } from 'react-redux';
import { login, useUserLoginMutation } from '../store';
import { useNavigate } from 'react-router-dom';
import { showLoadingNotificationEffect } from '../utils/helpers';
import { socket } from '../utils/socket';
function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [userLogin, userLoginResults] = useUserLoginMutation();

  function handleChangeEmail(event) {
    setEmail(event.target.value);
  }
  function handleChangePassword(event) {
    setPassword(event.target.value);
  }
  function handleOnSubmit(event) {
    event.preventDefault();
    userLogin({ email, password });
  }

  function handleSignup(event) {
    event.preventDefault();
    Notiflix.Report.info(
      'Registeration',
      'In order to signup, kindly contact admin at zuhoorhashimi5@gmail.com',
      'Okay'
    );
  }

  useEffect(() => {
    showLoadingNotificationEffect(userLoginResults, 'User', 'Login');
    if (userLoginResults.isSuccess) {
      const { user } = userLoginResults.data.data;
      const { token } = userLoginResults.data;
      dispatch(login({ user, token }));
      navigate('/dashboard', { replace: true });
      if (!socket.connected) {
        socket.connect();
      }
      Notification.requestPermission();
    }
  }, [userLoginResults, dispatch, navigate]);
  return (
    <section className="bg-dark-main h-screen w-screen flex items-center justify-center py-12 px-16">
      <div className="flex flex-col md:flex-row  items-center justify-center bg-dark-light-1 h-full w-full rounded-xl drop-shadow-md">
        <div className="w-full h-full rounded-l-xl flex justify-center p-4 flex-col gap-4">
          <div className="md:px-32">
            <div className="flex flex-col items-center gap-2">
              <h1 className="text-4xl font-bold text-accent-main">Log in</h1>
              <p className="text-accent-light">
                Don't have an account?{' '}
                <button className="text-accent-main" onClick={handleSignup}>
                  Sign up!
                </button>
              </p>
            </div>
            <form
              onSubmit={handleOnSubmit}
              className="flex flex-col items-center gap-4 w-full"
            >
              <div className="flex flex-col gap-2 w-full">
                <label className="font-semibold text-lg text-gray-100">
                  Email
                </label>
                <input
                  placeholder="ahmad@example.com"
                  className="p-3 bg-accent-light drop-shadow-sm w-full rounded-md shadow-sm text-dark-light-1 font-semibold"
                  type="email"
                  value={email}
                  onChange={handleChangeEmail}
                  required
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label className="font-semibold text-lg text-gray-100">
                  Password
                </label>
                <input
                  placeholder="*********"
                  className="p-3 bg-accent-light drop-shadow-sm w-full rounded-md shadow-sm text-dark-light-1 font-semibold"
                  type="password"
                  value={password}
                  onChange={handleChangePassword}
                  required
                />
                <button type="button" className="self-start text-accent-main">
                  Forgot my password
                </button>
              </div>
              <button
                type="submit"
                className="p-2 bg-accent-main rounded-md text-dark-light-1 font-bold w-full shadow-sm"
              >
                LOG IN
              </button>
            </form>
          </div>
        </div>
        <div className="w-2/3 h-full rounded-r-xl login-artwork"></div>
      </div>
    </section>
  );
}

export default LoginPage;
