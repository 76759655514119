import React from 'react';
import TodoItem from './TodoItem';
import { useGetUserTodosQuery } from '../../store';
import { showLoadingEffect } from '../../utils/helpers';

export default function TodoList() {
  const {
    data: userTodos,
    isLoading: userTodosIsLoading,
    isError: userTodosIsError,
    error: userTodosError,
  } = useGetUserTodosQuery();

  showLoadingEffect(
    userTodos,
    userTodosIsLoading,
    userTodosIsError,
    userTodosError
  );

  if (userTodos) {
    const todos = [...userTodos.todos];
    const todosSortedByPriority = todos.sort((a, b) => b.priority - a.priority);

    const finalTodos = [];
    todosSortedByPriority.forEach((todo) => {
      if (todo.completed) {
        finalTodos.push(todo);
      } else {
        finalTodos.unshift(todo);
      }
    });
    const renderedTodos = finalTodos.map((todo, i) => {
      return <TodoItem key={i} todo={todo} />;
    });

    return <div className="flex flex-col gap-2 mt-2">{renderedTodos}</div>;
  }
}
