import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';

const token = secureLocalStorage.getItem('jwt')
  ? secureLocalStorage.getItem('jwt')
  : null;
const user = secureLocalStorage.getItem('user')
  ? JSON.parse(secureLocalStorage.getItem('user'))
  : null;

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user,
    token,
  },
  reducers: {
    login(state, { payload }) {
      state.token = payload.token;
      state.user = payload.user;

      secureLocalStorage.setItem('user', JSON.stringify(payload.user));
      secureLocalStorage.setItem('jwt', payload.token);
    },
    logout(state, action) {
      secureLocalStorage.removeItem('jwt');
      secureLocalStorage.removeItem('user');
      state.token = null;
      state.user = null;
    },
  },
});

export const authReducer = authSlice.reducer;
export const { login, logout } = authSlice.actions;
