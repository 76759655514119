import { useParams } from 'react-router-dom';
import { setShowJobViewModal, useGetJobQuery } from '../store';
import JobDetail from '../components/job/JobView';
import Panel from '../components/uiComponents/Panel';
import Heading from '../components/uiComponents/Heading';
import { showLoadingEffect } from '../utils/helpers';
import Chat from '../components/job/Chat';

import { useEffect } from 'react';
import ItemsComponent from '../components/job/ItemsComponent';
import Button from '../components/uiComponents/Button';
import { HiPlus } from 'react-icons/hi2';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Scrollbars from 'rc-scrollbars';

function ViewJob() {
  const { viewJob } = useParams();
  const dispatch = useDispatch();
  const {
    data: job,
    isLoading,
    isError,
    error,
  } = useGetJobQuery(viewJob, { skip: !viewJob });

  useEffect(() => {
    showLoadingEffect(job, isLoading, isError, error);
  }, [error, isError, isLoading, job]);

  if (job) {
    const content = (
      <>
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex flex-col gap-2 p-4 w-full md:w-4/6 md:grid md:grid-cols-2 md:gap-4 md:p-8 md:h-5/6 text-gray-100 bg-dark-main rounded-lg"
        >
          <div className={'row-span-1 flex flex-col gap-4 z-40'}>
            <Panel className="flex justify-between">
              <Heading primary>Job Summary</Heading>
              {isMobile && (
                <Button
                  onClick={() => {
                    dispatch(setShowJobViewModal(false));
                  }}
                  className="flex items-center justify-center bg-accent-main"
                >
                  <HiPlus className="rotate-45 text-xl text-dark-light-2" />
                </Button>
              )}
            </Panel>
            <Panel className="z-50 h-1/2">
              <JobDetail job={job} />
            </Panel>
            <Panel className="h-[400px] md:h-1/2">
              <ItemsComponent job={job} />
            </Panel>
          </div>
          <div className="flex flex-col gap-4">
            <Panel className="hidden md:block">
              <Heading primary>Chats</Heading>
            </Panel>
            <Chat job={job} />
          </div>
        </div>
      </>
    );
    return isMobile ? <Scrollbars children={content} /> : content;
  }
}

export default ViewJob;
