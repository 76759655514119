import classNames from 'classnames';
function Panel({ className, children, ...rest }) {
  const classes = classNames(
    'p-4 bg-dark-light-1 drop-shadow-md rounded-md',
    className
  );
  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  );
}

export default Panel;
