import classNames from 'classnames';

function Input({ label, type, id, register, name, ref, ...rest }) {
  const classes = classNames('font-semibold text-gray-100');
  const inputClasses = classNames(
    'font-semibold p-2 bg-accent-light text-gray-600 rounded file:bg-indigo-600 file:rounded-full focus:ring focus:ring-orange-500 transition-all shadow autofill:!bg-accent-light'
  );

  return (
    <>
      <label className={classes} htmlFor={id}>
        {label}
      </label>
      <input
        className={inputClasses}
        type={type}
        id={id}
        {...register(id)}
        {...rest}
      />
    </>
  );
}

export default Input;
