import React, { useEffect } from 'react';
import Panel from '../components/uiComponents/Panel';
import Scrollbars from 'rc-scrollbars';
import JobComponent from '../components/job/JobComponent';
import {
  setShowJobViewModal,
  useGetAllCustomersQuery,
  useGetAllJobsQuery,
} from '../store';
import { showLoadingEffect } from '../utils/helpers';
import Heading from '../components/uiComponents/Heading';
import Modal from '../components/uiComponents/Modal';
import ViewJob from './ViewJob';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../components/uiComponents/Button';
import {
  setActiveTab,
  setShowAddCustomerModal,
} from '../store/slices/dataSlice';
import CustomerForm from '../components/customer/CustomerForm';

export default function CustomersPage() {
  const dispatch = useDispatch();
  const { showJobViewModal, showAddCustomerModal } = useSelector(
    (state) => state.data
  );
  const {
    data: jobsApi,
    isLoading: jobsIsLoading,
    isError: jobsIsError,
    error: jobsError,
  } = useGetAllJobsQuery();
  const {
    data: customersApi,
    isLoading: customersIsLoading,
    isError: customersIsError,
    error: customersError,
  } = useGetAllCustomersQuery();

  useEffect(() => {
    showLoadingEffect(jobsApi, jobsIsLoading, jobsIsError, jobsError);
  }, [jobsApi, jobsError, jobsIsError, jobsIsLoading]);

  useEffect(() => {
    showLoadingEffect(
      customersApi,
      customersIsLoading,
      customersIsError,
      customersError
    );
  }, [customersApi, customersIsLoading, customersIsError, customersError]);

  if (jobsApi && customersApi) {
    const customers = customersApi;
    const jobs = jobsApi;

    const renderedCustomerJobs = customers.map((customer) => {
      const filteredJobs = jobs.filter(
        (job) => job.customer._id === customer._id
      );
      return (
        <Panel>
          <Heading secondary className="mb-2">
            {customer.name}
          </Heading>
          <JobComponent filterCustomer data={filteredJobs} />
        </Panel>
      );
    });

    return (
      <div className="flex flex-col gap-4 h-full">
        {showJobViewModal && (
          <Modal setFunction={setShowJobViewModal}>
            <ViewJob />
          </Modal>
        )}
        {showAddCustomerModal && (
          <Modal setFunction={setShowAddCustomerModal}>
            <CustomerForm />
          </Modal>
        )}
        <Panel className="print:hidden">
          <div className="flex justify-between items-center">
            <Heading primary className={'text-center'}>
              Customers
            </Heading>
            <div className="flex flex-col md:flex-row gap-2">
              <Button
                onClick={() => {
                  dispatch(setShowAddCustomerModal(true));
                }}
              >
                add new customer
              </Button>
              <Button to={-1}>back</Button>
              <Button
                onClick={() => dispatch(setActiveTab('Dashboard'))}
                to={'/dashboard'}
              >
                Dashboard
              </Button>
            </div>
          </div>
        </Panel>
        <Scrollbars className="rounded-md">
          <div className="flex flex-col gap-4">{renderedCustomerJobs}</div>
        </Scrollbars>
      </div>
    );
  }
}
