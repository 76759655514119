import React, { useCallback, useEffect } from 'react';
import { HiDocument } from 'react-icons/hi2';
import { useDispatch } from 'react-redux';
import { setShowAttachmentModal, useAddMessageMutation } from '../../store';
import { useParams } from 'react-router-dom';

export default function ChatAttachmentModal({
  attachment,
  fileInputRef,
  setAttachment,
}) {
  const dispatch = useDispatch();
  const [addMessage, addMessageResults] = useAddMessageMutation();
  const { viewJob } = useParams();

  const handleClickCancel = useCallback(() => {
    setAttachment(null);

    fileInputRef.current.value = null;
    dispatch(setShowAttachmentModal(false));
  }, [dispatch, fileInputRef, setAttachment]);

  const handleClickSend = () => {
    const form = new FormData();
    form.append('attachment', attachment);
    form.append('message', 'attachment');
    addMessage({ form, jobId: viewJob });
  };
  useEffect(() => {
    if (addMessageResults.isSuccess) handleClickCancel();
  }, [addMessageResults, handleClickCancel]);

  return (
    <div className="relative z-50 top-0 bottom-0 right-0 left-0 bg-gray-800/40 w-full h-full">
      <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-4 bg-accent-main w-48 h-48 rounded-md shadow">
        <div className="relative flex flex-col items-center h-full w-full">
          <HiDocument className="text-dark-light-2 text-6xl " />
          <p className="text-dark-light-2 font-semibold mt-2 text-center overflow-auto">
            {attachment.name}
          </p>
          <p className="text-dark-light-2 font-semibold mt-2 text-center">
            {(attachment.size / 1024 / 1024).toFixed(2)} MB
          </p>
          <div className="absolute bottom-0">
            <div className="flex items-center w-full gap-4 bg-transparent">
              <button
                onClick={handleClickCancel}
                className="px-1 py-1 rounded bg-dark-light-2 text-accent-main w-14 hover:bg-dark-light-3 transition "
              >
                Cancel
              </button>
              <button
                onClick={handleClickSend}
                className="px-1 py-1 rounded bg-dark-light-2 text-accent-main w-14 hover:bg-dark-light-3 transition"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
