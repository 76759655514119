import Notiflix from 'notiflix';

export const showLoadingEffect = (data, isLoading, isError, error) => {
  Notiflix.Confirm.init({
    okButtonBackground: '#ef4444',
    titleColor: '#ef4444',
  });
  if (isLoading) {
    Notiflix.Loading.circle('Loading data...', { svgColor: `#cbd4cd` });
  }
  if (isError) {
    Notiflix.Loading.remove(500);
    Notiflix.Notify.failure('Failed to load data');
    console.log(error);
  }
  if (data) {
    Notiflix.Loading.remove(500);
  }
};

export const showLoadingNotificationEffect = (
  results,
  dataType,
  loadingType
) => {
  const { isLoading, isError, isSuccess, error } = results;
  if (isLoading) {
    Notiflix.Notify.info(`${loadingType} ${dataType}...`);
  }
  if (isSuccess) {
    Notiflix.Notify.success(`${dataType} ${loadingType} Successful!`);
  }
  if (isError) {
    Notiflix.Notify.failure(error.data.message);

    console.log(error);
  }
};

export const jobStatusOptions = [
  { value: 'designing', label: 'Designing' },
  { value: 'printing', label: 'Printing' },
  { value: 'binding', label: 'Binding' },
  { value: 'setForDelivery', label: 'Set for Delivery' },
  { value: 'delivered', label: 'Delivered' },
];

export const dropdownStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: 'transparent',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);',
    fontWeight: '500',
    borderWidth: '3px',
    textColor: '#2f2f2f',
    backgroundColor: '#ffdea7',
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    outlineColor: '#6366f1',
    outlineWidth: 200,
    backgroundColor: '#696969',
    textColor: '#2f2f2f',
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: '500',
  }),
  menuList: (baseStyles, state) => {
    return {
      ...baseStyles,
      backgroundColor: '#696969',
    };
  },
};

export const dropdownTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary50: '#ffdea7',
    primary25: '#ffdea7',
    primary: '#ffcb74',
  },
});
