import { Outlet } from 'react-router-dom';
import SideBar from '../navigation/Sidebar';
import Scrollbars from 'rc-scrollbars';
function Layout() {
  return (
    <div className="flex flex-col gap-2 md:grid md:grid-rows-1 md:grid-cols-6 md:gap-4 bg-dark-main p-4 h-screen">
      <div className="hidden md:block col-start-1 col-end-2 h-full bg-dark-light-1 rounded-lg ">
        <SideBar />
      </div>
      <div className="md:col-start-2 md:col-end-7 h-full">
        <Scrollbars autoHide>
          <Outlet />
        </Scrollbars>
      </div>
    </div>
  );
}

export default Layout;
