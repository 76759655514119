function Profile({ user }) {
  return (
    <>
      <img
        className="w-20 h-20 rounded-full"
        src={require('../../assets/img/login-artwork.png')}
        alt="user pictures"
      />
      <p className="font-bold text-xl text-gray-100 mt-2">
        {user.name.charAt(0).toUpperCase() + user.name.slice(1)}
      </p>
    </>
  );
}

export default Profile;
