import Tippy from '@tippyjs/react';
import React, { useEffect, useState } from 'react';
import {
  HiExclamationTriangle,
  HiOutlineExclamationTriangle,
} from 'react-icons/hi2';
import { useUpdateJobItemStatusMutation } from '../../store';

export default function JobItemStatus({ item }) {
  const [designActive, setDesignActive] = useState(false);
  const [printActive, setPrintActive] = useState(false);
  const [bindingActive, setBindingActive] = useState(false);

  const [forDeliveryActive, setForDeliveryActive] = useState(false);
  const [deliveredActive, setDeliveredActive] = useState(false);

  const [updateJobItemStatus] = useUpdateJobItemStatusMutation();

  useEffect(() => {
    if (item.status === 'designing') {
      setDesignActive(true);
      setPrintActive(false);
      setBindingActive(false);
      setForDeliveryActive(false);
      setDeliveredActive(false);
    }
    if (item.status === 'printing') {
      setDesignActive(true);
      setPrintActive(true);
      setBindingActive(false);
      setForDeliveryActive(false);
      setDeliveredActive(false);
    }
    if (item.status === 'binding') {
      setDesignActive(true);
      setPrintActive(true);
      setBindingActive(true);
      setForDeliveryActive(false);
      setDeliveredActive(false);
    }
    if (item.status === 'setForDelivery') {
      setDesignActive(true);
      setPrintActive(true);
      setBindingActive(true);
      setForDeliveryActive(true);
      setDeliveredActive(false);
    }
    if (item.status === 'delivered') {
      setDesignActive(true);
      setPrintActive(true);
      setBindingActive(true);
      setForDeliveryActive(true);
      setDeliveredActive(true);
    }
  }, [item]);

  const handleUpdateStatusClick = (status) => {
    updateJobItemStatus({ itemId: item._id, status });
  };

  const handleMarkUnMarkItemForShortage = () => {
    updateJobItemStatus({ itemId: item._id, shortage: !item.shortage });
  };

  return (
    <div className="flex gap-3 text-sm font-normal">
      <Tippy content="Design Pending">
        <button
          onClick={() => handleUpdateStatusClick('designing')}
          className={`rounded-full border cursor-pointer border-accent-main text-accent-main ${
            designActive && 'bg-accent-main text-dark-light-2'
          }  w-6 h-6 flex items-center justify-center`}
        >
          D
        </button>
      </Tippy>
      <Tippy content="Print Pending">
        <button
          onClick={() => handleUpdateStatusClick('printing')}
          className={`rounded-full border  cursor-pointer border-accent-main text-accent-main ${
            printActive && 'bg-accent-main text-dark-light-2'
          } w-6 h-6 flex items-center justify-center`}
        >
          P
        </button>
      </Tippy>
      <Tippy content="Binding Pending">
        <button
          onClick={() => handleUpdateStatusClick('binding')}
          className={`rounded-full border  cursor-pointer border-accent-main text-accent-main ${
            bindingActive && 'bg-accent-main text-dark-light-2'
          } w-6 h-6 flex items-center justify-center`}
        >
          B
        </button>
      </Tippy>
      <Tippy content="Set for Delivery">
        <button
          onClick={() => handleUpdateStatusClick('setForDelivery')}
          className={`rounded-full border  cursor-pointer border-accent-main text-accent-main ${
            forDeliveryActive && 'bg-accent-main text-dark-light-2'
          } w-6 h-6 flex items-center justify-center`}
        >
          S
        </button>
      </Tippy>{' '}
      <Tippy content="Delivered">
        <button
          onClick={() => handleUpdateStatusClick('delivered')}
          className={`rounded-full border  cursor-pointer border-accent-main text-accent-main ${
            deliveredActive && 'bg-accent-main text-dark-light-2'
          } w-6 h-6 flex items-center justify-center`}
        >
          D
        </button>
      </Tippy>{' '}
      {item.shortage ? (
        <Tippy content="Unmark for shortage">
          <button
            onClick={() => handleMarkUnMarkItemForShortage()}
            className="text-rose-300 text-2xl"
          >
            <HiExclamationTriangle />
          </button>
        </Tippy>
      ) : (
        <Tippy content="Mark for shortage">
          <button
            onClick={() => handleMarkUnMarkItemForShortage()}
            className="text-rose-300 text-2xl"
          >
            <HiOutlineExclamationTriangle />
          </button>
        </Tippy>
      )}
    </div>
  );
}
