import HoverNavigation from './HoverNavigation';
import NavLink from '../uiComponents/Link';

import { useState } from 'react';

function MenuItems({ items }) {
  const [barIsOpen, setBarIsOpen] = useState(false);
  const handleMouseEnter = () => {
    setBarIsOpen(true);
  };

  return (
    <div>
      {items.submenu ? (
        <>
          <div>
            <NavLink
              icon={items.icon}
              onMouseEnter={handleMouseEnter}
              link={items}
            ></NavLink>
            <HoverNavigation
              barIsOpen={barIsOpen}
              setBarIsOpen={setBarIsOpen}
              submenus={items.submenu}
            />{' '}
          </div>
        </>
      ) : (
        <NavLink icon={items.icon} link={items}></NavLink>
      )}
    </div>
  );
}
export default MenuItems;
