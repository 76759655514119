import { useEffect, useRef } from 'react';
import NavLink from '../uiComponents/Link';

function HoverNavigation({ submenus, barIsOpen, setBarIsOpen }) {
  const DivEl = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!DivEl.current) {
        return;
      }
      if (!DivEl.current.contains(event.target)) {
        setBarIsOpen(false);
      }
    };
    document.addEventListener('click', handler, true);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, [setBarIsOpen]);

  const renderedLinks = submenus.map((link, i) => {
    return <NavLink key={i} to={link.path} link={link}></NavLink>;
  });

  return (
    <div
      ref={DivEl}
      onMouseLeave={() => setBarIsOpen(false)}
      className={` ${
        barIsOpen === true ? '' : 'hidden'
      } absolute left-full ml-2 top-0 bg-indigo-50 text-gray-800 rounded-md shadow-md p-4 w-[20rem]`}
    >
      <div>
        <div className="mt-2 flex flex-col gap-4 grid-cols-2">
          {renderedLinks}
        </div>
      </div>
    </div>
  );
}

export default HoverNavigation;
