import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    activeTab: 'Dashboard',
    showJobAddEditModal: false,
    showJobViewModal: false,
    showAddCustomerModal: false,
    showAttachmentModal: false,
  },
  reducers: {
    setShowJobAddEditModal(state, { payload }) {
      state.showJobAddEditModal = payload;
    },
    setShowJobViewModal(state, { payload }) {
      state.showJobViewModal = payload;
    },
    setShowAddCustomerModal(state, { payload }) {
      state.showAddCustomerModal = payload;
    },
    setShowAttachmentModal(state, { payload }) {
      state.showAttachmentModal = payload;
      // state.showAttachmentModal = !state.showAttachmentModal;
    },
    setActiveTab(state, { payload }) {
      state.activeTab = payload;
    },
  },
});

export const dataReducer = dataSlice.reducer;
export const {
  setShowJobAddEditModal,
  setShowJobViewModal,
  setShowAddCustomerModal,
  setShowAttachmentModal,
  setActiveTab,
} = dataSlice.actions;
