import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import LoginPage from './pages/loginPage';
import Dashboard from './pages/dashboard';
import Layout from './components/uiComponents/Layout';
import CustomersPage from './pages/customersPage';
import {
  setShowAddCustomerModal,
  setShowJobAddEditModal,
  setShowJobViewModal,
} from './store';
function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (token) {
      navigate('/dashboard', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const turnOffModals = (e) => {
      if (e.key === 'Escape') {
        const modals = [
          setShowAddCustomerModal,
          setShowJobAddEditModal,
          setShowJobViewModal,
        ];
        modals.forEach((modal) => {
          dispatch(modal(false));
        });
      }
    };

    document.addEventListener('keyup', turnOffModals);
    return () => {
      document.removeEventListener('keyup', turnOffModals);
    };
  }, [dispatch]);
  return (
    <Routes>
      <Route
        path="/"
        Component={() => {
          if (!token) {
            return <LoginPage />;
          }
        }}
      />

      {token && (
        <>
          <Route path="/dashboard/:view?/:viewJob?" Component={Layout}>
            <Route index Component={Dashboard} />
            <Route
              path="customers/:view?/:viewJob?"
              Component={CustomersPage}
            />
          </Route>
        </>
      )}
      <Route
        path="*"
        Component={() => (
          <>
            <div>You are not Logged in</div>{' '}
            <button onClick={() => navigate('/')}>Login</button>
          </>
        )}
      ></Route>
    </Routes>
  );
}

export default App;
