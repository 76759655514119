import React, { useEffect, useRef, useState } from 'react';
import Panel from '../uiComponents/Panel';
import {
  setShowAttachmentModal,
  useAddMessageMutation,
  useUpdateJobMutation,
} from '../../store';
import { HiDocument, HiMiniPaperClip, HiPaperAirplane } from 'react-icons/hi2';
import Scrollbars from 'rc-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ChatAttachmentModal from './ChatAttachmentModal';
import { saveAs } from 'file-saver';

export default function Chat({ job }) {
  const dispatch = useDispatch();
  const { viewJob } = useParams();
  const chatScrollbar = useRef(null);
  const fileInputRef = useRef(null);

  const { user } = useSelector((state) => state.authentication);
  const { showAttachmentModal } = useSelector((state) => state.data);

  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState();

  const [addMessage, addMessageResults] = useAddMessageMutation();
  const [updateJob] = useUpdateJobMutation();

  useEffect(() => {
    addMessageResults.isSuccess && setMessage('');
  }, [addMessageResults]);

  useEffect(() => {
    chatScrollbar.current?.scrollToBottom();
  }, [job]);

  useEffect(() => {
    attachment
      ? dispatch(setShowAttachmentModal(true))
      : dispatch(setShowAttachmentModal(false));
  }, [attachment, dispatch]);

  useEffect(() => {
    async function readMessages() {
      if (job.messages.length > 0) {
        let markAsSeen = false;
        job.messages.forEach((message) => {
          if (!message.seenBy.includes(user._id)) {
            markAsSeen = true;
          }
        });
        if (markAsSeen) await updateJob({ jobId: job._id, messageSeen: true });
      }
      if (!job.seenBy.includes(user._id)) {
        await updateJob({ jobId: job._id, seenBy: user._id });
      }
    }
    readMessages();
  }, [job, updateJob, user]);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleAttachmentChange = (event) => {
    setAttachment(event.target.files[0]);
  };

  const handleClickAttach = () => {
    fileInputRef.current.click();
  };

  const messageSend = (event) => {
    event.preventDefault();
    if (message.length === 0) return;
    const body = { message, jobId: viewJob };
    setMessage('');
    addMessage(body);
  };

  const handleDownloadFile = (message) => {
    console.log(message.attachment);
    saveAs(`${window.location.origin}/uploads`, message.attachment.filename);
  };
  const renderMessages = () => {
    const messages = job.messages.map((message, i, array) => {
      if (array.length > 0) {
        // <div className={`flex flex-col mt-4`}>{renderedMessages}</div>

        return (
          <div
            key={message._id}
            className={`flex flex-col ${
              message.attachment ? 'w-48' : 'w-1/2'
            } ${message.from._id === user._id ? 'self-end' : 'self-start'}`}
          >
            <p
              className={`text-accent-main
         ${message.from._id === user._id ? 'self-end' : 'self-start'}`}
            >
              {message.from.name}
            </p>
            <div
              className={`p-2 rounded w-full ${
                message.attachment ? 'h-24 relative' : ''
              }
          ${
            message.from._id === user._id
              ? 'bg-dark-light-3 text-gray-100'
              : 'bg-accent-main text-dark-light-1 '
          }`}
            >
              {message.attachment ? (
                <button
                  className={`hover:underline absolute bottom-1/2 right-1/2 translate-y-1/2 translate-x-1/2 flex flex-col items-center overflow-auto`}
                  onClick={() => handleDownloadFile(message)}
                >
                  {' '}
                  {<HiDocument className="text-4xl" />}
                  {message.attachment.filename.length > 20
                    ? `${message.attachment.filename.slice(0, 15)}...'.${
                        message.attachment.fileType
                      }`
                    : message.attachment.filename}
                </button>
              ) : (
                message.message
              )}
            </div>
            <p
              className={`text-gray-100 text-xs  ${
                message.from._id === user._id ? 'self-start' : 'self-end'
              }`}
            >
              {new Date(message.dateCreated).toLocaleString('en-us', {
                month: 'short',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })}
            </p>
          </div>
        );
      } else return null;
    });
    if (messages.length === 0) {
      messages.splice(
        0,
        9999,
        <div key="randomKey" className="text-lg text-gray-200 self-center">
          <p>Start chatting...</p>
        </div>
      );
    }

    return <div className={`flex flex-col`}>{messages}</div>;
  };

  const renderedMessages = renderMessages();

  return (
    <Panel
      className={'bg-dark-light-1 flex flex-col gap-2 h-[600px] md:h-full'}
    >
      {/* <div className={'bg-dark-light-2 rounded-md grid gap-2 h-[560px] p-4'}> */}
      <div
        className={`absolute top-0 right-0 bottom-0 left-0 ${
          !showAttachmentModal && 'hidden'
        }`}
      >
        {showAttachmentModal && (
          <ChatAttachmentModal
            attachment={attachment}
            setAttachment={setAttachment}
            fileInputRef={fileInputRef}
          />
        )}
      </div>
      <Scrollbars ref={chatScrollbar}>{renderedMessages}</Scrollbars>
      {/* </div> */}
      <form onSubmit={messageSend} className="sticky">
        <div className="flex gap-2 items-center align-center">
          <input
            type="text"
            placeholder="Type your message..."
            value={message}
            onChange={handleMessageChange}
            required
            className=" px-4 py-2 w-full bg-dark-light-2 rounded text-accent-main placeholder:text-accent-light focus:ring focus:ring-orange-500 transition"
          />
          <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={handleAttachmentChange}
          />
          <div
            onClick={handleClickAttach}
            className="rounded p-2 shadow-md bg-accent-main cursor-pointer"
          >
            <HiMiniPaperClip className=" text-dark-light-1 text-2xl" />
          </div>
          <div
            onClick={messageSend}
            className="rounded p-2 shadow-md bg-accent-main cursor-pointer"
          >
            <HiPaperAirplane className=" text-dark-light-1 text-2xl" />
          </div>
        </div>
      </form>
    </Panel>
  );
}
