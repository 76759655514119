import SortableTable from '../uiComponents/SortableTable';
import { Link } from 'react-router-dom';
import { HiEye, HiPencilSquare, HiTrash } from 'react-icons/hi2';
import {
  setShowJobAddEditModal,
  setShowJobViewModal,
  useDeleteJobMutation,
} from '../../store';
import Notiflix from 'notiflix';
import { useDispatch, useSelector } from 'react-redux';

import Tippy from '@tippyjs/react';

function JobComponent({ data, filterCustomer }) {
  const dispatch = useDispatch();
  const [deleteJob] = useDeleteJobMutation();
  const { user } = useSelector((state) => state.authentication);

  const handleClickDelete = (job) => {
    Notiflix.Confirm.init({
      okButtonBackground: '#dc2626',
      titleColor: '#dc2626',
      borderRadius: '25px',
    });
    Notiflix.Confirm.show(
      'Delete Job',
      'Are you sure you want to delete the job?',
      'Yes',
      'No',
      () => deleteJob(job)
    );
  };
  // console.log(deleteJobResults);

  const handleClickViewJob = (job) => {
    dispatch(setShowJobViewModal(true));
  };

  const config = [
    {
      label: 'Customer',
      render: (job) => {
        const customerInitials = job.customer.initials.toUpperCase();
        let jobReadBadge = null;
        let newMessagesBadge = null;
        let newMessagesCount = 0;

        if (job.messages.length > 0) {
          job.messages.forEach((message) => {
            if (!message.seenBy.includes(user._id)) {
              newMessagesCount++;
            }
          });
        }
        if (newMessagesCount > 0) {
          newMessagesBadge = (
            <span className="ml-2 bg-accent-main rounded-full font-bold text-dark-light-1 px-2 text-sm">
              {newMessagesCount} new message(s)
            </span>
          );
        }
        if (!job.seenBy.includes(user._id)) {
          jobReadBadge = (
            <span className="ml-2 bg-accent-main rounded-full font-bold text-dark-light-1 px-2 text-sm">
              Unread
            </span>
          );
        }
        return [customerInitials, jobReadBadge, newMessagesBadge];
      },
    },
    { label: 'Title', render: (job) => job.title },
    {
      label: 'Deadline',
      render: (job) =>
        new Date(job.deadline).toLocaleString('en-uk', {
          year: 'numeric',
          day: 'numeric',
          month: 'numeric',
        }),
    },
    {
      label: 'Status',
      render: (job) => {
        let shortageCount = job.items.filter(
          (item) => item.shortage === true
        ).length;

        console.log(shortageCount);
        const setForDeliveryCount = job.items.filter(
          (item) => item.status === 'setForDelivery'
        ).length;

        const shortageBadge = (
          <span className="bg-rose-200 text-rose-800 font-bold px-3 py-1 rounded">
            Shortage
          </span>
        );
        const setForDeliveryBadge = setForDeliveryCount > 0 && (
          <span className="bg-green-200 text-green-800 font-bold px-3 py-1 rounded">
            {`${setForDeliveryCount} item${
              setForDeliveryCount > 1 ? 's' : ''
            } ready`}
          </span>
        );

        if (job.status === 'delivered') {
          return (
            <>
              <span className="bg-green-200 text-green-800 font-bold px-3 py-1 rounded">
                Delivered
              </span>{' '}
              {setForDeliveryBadge}
              {shortageCount > 0 && shortageBadge}
            </>
          );
        } else {
          return (
            <>
              <span className="bg-rose-200 text-rose-800 font-bold px-3 py-1 rounded">
                {job.status[0].toUpperCase() +
                  job.status
                    .slice(1)
                    .split(/(?=[A-Z])/)
                    .join(' ')}
              </span>{' '}
              {setForDeliveryBadge} {shortageCount > 0 && shortageBadge}
            </>
          );
        }
      },
    },
    {
      label: 'Actions',
      render: (job) => (
        <div className="flex gap-2">
          <Tippy content="View">
            <Link
              to={`/dashboard${
                window.location.pathname.includes('customers')
                  ? '/customers'
                  : ''
              }/view/${job._id}`}
              onClick={() => handleClickViewJob(job)}
            >
              <HiEye />
            </Link>
          </Tippy>
          <Tippy content="Edit">
            <Link
              onClick={() => dispatch(setShowJobAddEditModal(true))}
              to={`/dashboard/view/${job._id}`}
            >
              <HiPencilSquare />
            </Link>
          </Tippy>
          <Tippy content="Delete">
            <button onClick={() => handleClickDelete(job)}>
              <HiTrash />
            </button>
          </Tippy>
        </div>
      ),
    },
  ];

  if (filterCustomer) {
    config.splice(0, 1);
  }
  return <SortableTable config={config} data={data} />;
}

export default JobComponent;
