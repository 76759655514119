import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setActiveTab } from '../../store';
function NavLink({ link, icon, ...rest }) {
  const dispatch = useDispatch();
  const { activeTab } = useSelector((state) => state.data);
  const classes = classNames(
    link.label === activeTab && 'font-bold text-dark-light-1'
  );

  return (
    <div
      className={`relative flex items-center gap-4 p-2  ${classes} ${
        link.label === activeTab
          ? 'p-2 bg-accent-main rounded-lg text-dark-light-1'
          : 'bg-dark-light-1 text-gray-100'
      } transition-all`}
    >
      <div className={`text-xl ${classes}`}>{icon}</div>
      <Link
        onClick={() => dispatch(setActiveTab(link.label))}
        to={link.path}
        {...rest}
        className={`w-full font-semibold ${classes}`}
      >
        {link.label}
      </Link>
    </div>
  );
}

export default NavLink;
