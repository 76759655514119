import React from 'react';

import Scrollbars from 'rc-scrollbars';
import Heading from '../uiComponents/Heading';
import Table from '../uiComponents/Table';
import JobItemStatus from './JobItemStatus';
export default function ItemsComponent({ job }) {
  const config = [
    {
      label: 'Item',
      render: (item) => item.name,
    },
    {
      label: 'Quantity',
      render: (item) => item.orderedQuantity,
    },
    {
      label: 'Status',
      render: (item) => <JobItemStatus item={item} />,
    },
  ];

  return (
    <Scrollbars
      children={
        <>
          <Heading secondary className="mb-2">
            Items
          </Heading>
          <Table config={config} data={job.items} />
        </>
      }
      className="rounded-lg"
    />
  );
}
