import { Link } from 'react-router-dom';

function Button({ className, to, children, ...rest }) {
  return (
    <Link
      {...rest}
      className={`p-2 text-md font-bold uppercase bg-accent-main h-10 text-center text-dark-light-1 rounded-md hover:bg-accent-light transition ${className}`}
      to={to}
    >
      {children}
    </Link>
  );
}

export default Button;
