import React from 'react';
import { HiMiniCheckCircle, HiMiniTrash, HiMiniXCircle } from 'react-icons/hi2';
import Tippy from '@tippyjs/react';
import {
  useDeleteUserTodoMutation,
  useUpdateUserTodoMutation,
} from '../../store';
export default function TodoItem({ todo }) {
  const [updateTodo] = useUpdateUserTodoMutation();
  const [deleteTodo] = useDeleteUserTodoMutation();

  const markTodoAsComplete = () => {
    updateTodo({ todoId: todo._id, completed: true });
  };

  const markTodoAsNotComplete = () => {
    updateTodo({ todoId: todo._id, completed: false });
  };
  const handleDeleteTodo = () => {
    deleteTodo(todo._id);
  };
  return (
    <div
      className={`w-full rounded-lg ${
        todo.completed
          ? 'bg-accent-main text-dark-light-1'
          : 'bg-red-500 text-gray-100'
      } shadow p-2 flex items-center transition-all duration-200`}
    >
      <div
        className={`rounded-full w-4 h-4 ${
          todo.priority === 0
            ? 'bg-red-500'
            : todo.priority === 1
            ? 'bg-yellow-500'
            : 'bg-blue-500'
        } border border-white mr-2`}
      ></div>
      <div className="flex justify-between w-full">
        <p className={` font-semibold ${todo.completed && 'line-through'}`}>
          {todo.task}
        </p>
        <div
          className={`flex justify-between ${
            todo.completed ? 'text-dark-light-2' : 'text-gray-100'
          } `}
        >
          {todo.completed ? (
            <Tippy content="Mark as incomplete">
              <button onClick={markTodoAsNotComplete}>
                <HiMiniXCircle />
              </button>
            </Tippy>
          ) : (
            <Tippy content="Mark as complete">
              <button onClick={markTodoAsComplete}>
                <HiMiniCheckCircle />
              </button>
            </Tippy>
          )}

          <Tippy content="Delete">
            <button onClick={() => handleDeleteTodo()}>
              <HiMiniTrash />
            </button>
          </Tippy>
        </div>
      </div>
    </div>
  );
}
