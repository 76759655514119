import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { socket } from '../../utils/socket';

const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_PROXY_URL}/api/v1`,
    prepareHeaders: (header, { getState }) => {
      const { token } = getState().authentication;
      header.set('authorization', `Bearer ${token}`);
    },
  }),
  endpoints(builder) {
    return {
      userLogin: builder.mutation({
        query: (body) => {
          return {
            url: '/users/login',
            method: 'POST',
            body,
          };
        },
      }),
      userLogout: builder.mutation({
        query: () => {
          return {
            url: '/users/logout',
            method: 'GET',
          };
        },
      }),
      getAllCustomers: builder.query({
        providesTags: ['customers'],
        query: () => {
          return { url: '/customers', method: 'GET' };
        },
      }),
      addCustomer: builder.mutation({
        invalidatesTags: ['customers'],
        query: (body) => {
          return {
            url: '/customers',
            method: 'POST',
            body,
          };
        },
      }),
      getAllJobs: builder.query({
        providesTags: [{ type: 'jobs' }],
        query: () => {
          return { url: '/jobs', method: 'GET' };
        },
        async onCacheEntryAdded(
          arg,
          { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
        ) {
          try {
            await cacheDataLoaded;
            socket.on('jobAdded', (job) => {
              updateCachedData((draft) => [job, ...draft]);
            });
            socket.on('jobUpdated', (job) => {
              updateCachedData((draft) => {
                const oldJobIndex = draft.findIndex(
                  (draftJob) => draftJob._id === job._id
                );
                draft.splice(oldJobIndex, 1, job);
              });
            });
            socket.on('jobDeleted', (job) => {
              updateCachedData((draft) => {
                const oldJobIndex = draft.findIndex(
                  (draftJob) => draftJob._id === job._id
                );
                draft.splice(oldJobIndex, 1);
              });
            });
          } catch (error) {
            console.log('Error in all jobs query: socket', error);
          }
          await cacheEntryRemoved;
        },
      }),
      getJob: builder.query({
        providesTags: [{ type: 'job' }],
        query: (jobId) => {
          return {
            url: `/jobs/${jobId}`,
            method: 'GET',
          };
        },
        async onCacheEntryAdded(
          arg,
          { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
        ) {
          try {
            await cacheDataLoaded;
            socket.on('jobUpdated', (job) => {
              updateCachedData((draft) => {
                return draft._id === job._id ? job : draft;
              });
            });
          } catch (error) {
            console.log('error in socket (one job fetch)', error);
          }
          await cacheEntryRemoved;
        },
      }),
      addJob: builder.mutation({
        query: (body) => {
          return {
            url: '/jobs',
            method: 'POST',
            body,
          };
        },
      }),
      deleteJob: builder.mutation({
        invalidatesTags: [{ type: 'jobs' }],
        query: (job) => {
          return {
            url: `/jobs/${job._id}`,
            method: 'DELETE',
          };
        },
      }),

      updateJob: builder.mutation({
        query: ({ jobId, ...patch }) => {
          return {
            url: `/jobs/${jobId}`,
            method: 'PATCH',
            body: patch,
          };
        },
      }),
      updateJobItemStatus: builder.mutation({
        query: ({ itemId, ...patch }) => {
          return {
            url: `/jobs/items/${itemId}`,
            method: 'PATCH',
            body: patch,
          };
        },
      }),
      addMessage: builder.mutation({
        // invalidatesTags: [{ type: 'job' }],
        query: ({ jobId, ...body }) => {
          let message = body.form ? body.form : body;

          return {
            url: `/jobs/${jobId}/chat`,
            method: 'POST',
            body: message,
          };
        },
      }),
      getUserTodos: builder.query({
        providesTags: ['todos'],
        query: () => {
          return {
            url: `/todos/user`,
            method: 'GET',
          };
        },
      }),
      updateUserTodo: builder.mutation({
        invalidatesTags: ['todos'],
        query: ({ todoId, ...patch }) => {
          return {
            url: `/todos/todoTask/${todoId}`,
            method: 'PATCH',
            body: patch,
          };
        },
      }),
      deleteUserTodo: builder.mutation({
        invalidatesTags: ['todos'],
        query: (todoId) => {
          return {
            url: `/todos/todoTask/${todoId}`,
            method: 'DELETE',
          };
        },
      }),
      createUserTodo: builder.mutation({
        invalidatesTags: ['todos'],
        query: (body) => {
          return {
            url: `/todos/todoTask`,
            method: 'POST',
            body,
          };
        },
      }),
    };
  },
});

export { mainApi };
export const {
  useUserLoginMutation,
  useUserLogoutMutation,
  useAddCustomerMutation,
  useAddJobMutation,
  useAddMessageMutation,
  useDeleteJobMutation,
  useUpdateJobItemStatusMutation,
  useGetAllCustomersQuery,
  useGetAllJobsQuery,
  useGetJobQuery,
  useGetUserTodosQuery,
  useUpdateUserTodoMutation,
  useUpdateJobMutation,
  useDeleteUserTodoMutation,
  useCreateUserTodoMutation,
} = mainApi;
