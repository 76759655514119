import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useUpdateJobMutation } from '../../store';
import {
  dropdownStyles,
  dropdownTheme,
  jobStatusOptions,
} from '../../utils/helpers';

function JobDetail({ job }) {
  const [updateJob] = useUpdateJobMutation();

  const [selectedStatus, setSelectedStatus] = useState();

  const handleDropDownChange = (option) => {
    setSelectedStatus(option.value);

    const body = { status: option.value, jobId: job._id };
    // console.log(body);
    updateJob(body);
  };
  useEffect(() => {
    setSelectedStatus(
      jobStatusOptions.filter((option) => option.value === job.status)[0]
    );
  }, [job]);
  if (job) {
    console.log(job);
    return (
      <div className="grid grid-cols-2 md:grid-cols-3 mt-2 gap-y-4 gap-x-2 justify-center">
        <div className="justify-between col-span-2 md:col-span-1">
          <div className="flex flex-col gap-1">
            <h3 className="font-bold">Customer:</h3>
            <p className="text-md font-semibold">{job.customer.name}</p>
          </div>
        </div>
        <div className="justify-between col-start-1 md:col-start-2 row-start-2 md:row-start-1">
          <div className="flex flex-col gap-1">
            <h3 className="font-bold">Title:</h3>
            <p className="font-semibold">{job.title}</p>
          </div>
        </div>
        <div className="justify-between col-span-3 md:col-span-2">
          <div className="flex flex-col gap-1">
            <h3 className="font-bold">Description:</h3>
            <p className="font-semibold">{job.description}</p>
          </div>
        </div>
        <div className="justify-between col-span-3 md:col-span-1">
          <div className="flex flex-col gap-1">
            <h3 className="font-bold">Order Date:</h3>
            <p className="font-semibold">
              {new Date(job.dateCreated).toLocaleString('en-uk', {
                year: 'numeric',
                day: 'numeric',
                month: 'numeric',
              })}
            </p>
          </div>
        </div>
        <div className="justify-between row-start-2 md:row-start-1 col-start-2 md:col-start-3">
          <div className="flex flex-col gap-1">
            <h3 className="font-bold">Deadline:</h3>
            <p className="font-semibold">
              {new Date(job.deadline).toLocaleString('en-uk', {
                year: 'numeric',
                day: 'numeric',
                month: 'numeric',
              })}
            </p>
          </div>
        </div>
        <div className="justify-between col-span-2 md:col-span-1">
          <div className="flex flex-col gap-1">
            <h3 className="font-bold">Status:</h3>
            {/* <p className="text-lg font-semibold">{job.status}</p> */}
            <Select
              theme={dropdownTheme}
              styles={dropdownStyles}
              value={selectedStatus}
              options={jobStatusOptions}
              onChange={(value) => handleDropDownChange(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default JobDetail;
