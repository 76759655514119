import NavLink from '../uiComponents/Link';
import Profile from './Profile';
import { IoIosExit } from 'react-icons/io';
import MenuItems from './MenuItems';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { useUserLogoutMutation } from '../../store';
import { mainApi } from '../../store/apis/mainApi';
import { HiHome, HiUserGroup, HiUsers } from 'react-icons/hi2';

// import { useNavigate } from 'react-router-dom';

function SideBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);

  const [userLogout] = useUserLogoutMutation();

  const logoutLink = { label: 'Log Out', path: '/' };
  const menuItems = [
    { label: 'Dashboard', path: '/dashboard', icon: <HiHome /> },
    {
      label: 'Customers',
      path: '/dashboard/customers',
      icon: <HiUserGroup />,
    },

    { label: 'Users', path: '/dashboard/users', icon: <HiUsers /> },
  ];

  const handleLogout = () => {
    dispatch(mainApi.util.resetApiState());
    dispatch(logout());
    userLogout();
    navigate('/', { replace: true });
  };

  const renderedNavLinks = menuItems.map((menu, index) => {
    return <MenuItems items={menu} key={index} />;
  });
  return (
    <div className="flex flex-col items-center justify-between h-full p-4 shadow-md">
      <div className="flex flex-col items-center">
        <Profile user={user} />
      </div>

      <div className="relative  text-gray-100 w-full p-4 flex flex-col gap-4 rounded-md z-40 ">
        {renderedNavLinks}
        <div className="mt-36">
          <NavLink
            onClick={handleLogout}
            link={logoutLink}
            replace={true}
            icon={<IoIosExit />}
          ></NavLink>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
