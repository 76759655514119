import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { authReducer } from './slices/authSlice';
import { dataReducer } from './slices/dataSlice';
import { mainApi } from './apis/mainApi';

const store = configureStore({
  reducer: {
    authentication: authReducer,
    data: dataReducer,
    [mainApi.reducerPath]: mainApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(mainApi.middleware);
  },
});

setupListeners(store.dispatch);

export { store };
export {
  useUserLoginMutation,
  useUserLogoutMutation,
  useGetAllJobsQuery,
  useAddJobMutation,
  useGetJobQuery,
  useUpdateJobMutation,
  useDeleteJobMutation,
  useUpdateJobItemStatusMutation,
  useAddMessageMutation,
  useGetAllCustomersQuery,
  useGetUserTodosQuery,
  useUpdateUserTodoMutation,
  useAddCustomerMutation,
  useCreateUserTodoMutation,
  useDeleteUserTodoMutation,
} from './apis/mainApi';

export { login, logout } from './slices/authSlice';
export {
  setShowJobAddEditModal,
  setShowJobViewModal,
  setShowAddCustomerModal,
  setShowAttachmentModal,
  setActiveTab,
} from './slices/dataSlice';
