import Scrollbars from 'rc-scrollbars';
import React, { useEffect, useRef, useState } from 'react';
import TodoList from './TodoList';
import { useCreateUserTodoMutation } from '../../store';
import {
  dropdownStyles,
  dropdownTheme,
  showLoadingNotificationEffect,
} from '../../utils/helpers';
import Select from 'react-select';
import { HiPaperAirplane } from 'react-icons/hi2';

function TodoComponent() {
  const [createTodo, createTodoResults] = useCreateUserTodoMutation();
  const [todoText, setTodoText] = useState('');
  const [todoPriority, setTodoPriority] = useState(0);
  const todoInputRef = useRef(null);

  useEffect(() => {
    showLoadingNotificationEffect(createTodoResults, 'Todo', 'Creating');
    if (createTodoResults.isSuccess) {
      setTodoText('');
      todoInputRef.current?.blur();
      setTodoPriority(0);
    }
  }, [createTodoResults]);

  const handleCreateTodo = (e) => {
    e.preventDefault();
    const body = {
      task: todoText,
      completed: false,
      priority: todoPriority.value,
    };
    // console.log(body);
    createTodo(body);
  };

  const todoPriorityOptions = [
    { value: 0, label: 'High' },
    { value: 1, label: 'Medium' },
    { value: 2, label: 'Low' },
  ];
  return (
    <>
      <div className="h-[300px] md:h-full pb-28 md:pb-20">
        <Scrollbars className="rounded-lg">
          <TodoList />
        </Scrollbars>
      </div>
      <form
        onSubmit={handleCreateTodo}
        className="absolute bottom-0 right-0 left-0 m-2"
      >
        {' '}
        <div className="relative w-full">
          <div className="flex gap-1 w-full">
            <input
              ref={todoInputRef}
              value={todoText}
              required
              onChange={(e) => setTodoText(e.target.value)}
              className="rounded px-3 py-2 focus:ring bg-accent-light placeholder:text-dark-light-3 focus:ring-orange-500 transition-all w-4/6"
              placeholder="Create a new task"
            />
            <Select
              options={todoPriorityOptions}
              value={todoPriority}
              onChange={setTodoPriority}
              styles={dropdownStyles}
              theme={dropdownTheme}
              className="w-2/6"
              placeholder="Priority"
              required
            />
          </div>
          <button
            type="submit"
            className="absolute top-0 right-0 rounded p-2 shadow-lg bg-indigo-500 cursor-pointer hover:bg-indigo-600 transition hidden"
          >
            <HiPaperAirplane className=" text-indigo-50 text-2xl " />
          </button>
        </div>
      </form>
    </>
  );
}

export default TodoComponent;
